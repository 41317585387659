import ImportantIcon from "src/ui/icons/important-outlined.svg"
import { MBanner, MButtonProps } from "src/ui/MBanner/MBanner"

export function ImportantBanner({
  children,
  ...props
}: Omit<MButtonProps, "icon" | "horizontal">) {
  return (
    <MBanner {...props} icon={<ImportantIcon width={24} />} horizontal>
      {children}
    </MBanner>
  )
}
