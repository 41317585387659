import { useNotifications } from "src/components/Notifications/useNotifications"
import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { HREF_MINUT_HELP_DESKTOP_NOTIFICATIONS } from "src/constants/hrefs"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useFetchUser } from "src/data/user/queries/userQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { LearnMore } from "src/ui/Link/LearnMore"

export function DesktopNotificationsToggle() {
  const { t, langKeys } = useTranslate()
  const {
    notificationsEnabled,
    setNotificationsEnabled,
    browserPermission,
    loading,
  } = useNotifications()

  const { user_id: userId } = useGetUser()
  // fetching user here to ensure this component stays up to date with BE:
  const fetchUser = useFetchUser({ userId })

  const browserPermissionDenied = t(langKeys.placeholder_web, {
    text: `Couldn't turn on web app notifications. To
  receive them, enable notifications for web.minut.com in your browser settings.
  Then refresh this page and try toggling them on again.`,
  })

  async function handleNotificationToggle(value: boolean) {
    const result = await setNotificationsEnabled(value)

    if (result.ok === false) {
      if (result.error === "permission-denied") {
        return { isSuccess: false, message: browserPermissionDenied }
      }

      // Generic error:
      return {
        isSuccess: false,
        message: t(langKeys.failed_something_went_wrong),
      }
    }

    return { isSuccess: true }
  }
  const title = t(langKeys.desktop_notifications_title)
  const description = (
    <div>
      {t(langKeys.desktop_notifications_description)}{" "}
      <LearnMore href={HREF_MINUT_HELP_DESKTOP_NOTIFICATIONS} />
    </div>
  )
  const tooltip = (
    <div>
      {t(langKeys.desktop_notifications_supported)}{" "}
      <LearnMore href={HREF_MINUT_HELP_DESKTOP_NOTIFICATIONS} />
    </div>
  )

  return (
    <SettingToggle
      title={title}
      description={description}
      onSave={async (checked: boolean) =>
        await handleNotificationToggle(checked)
      }
      value={notificationsEnabled}
      disabled={
        browserPermission === "denied" || fetchUser.isLoading || loading
      }
      errorInitial={
        browserPermission === "denied" ? browserPermissionDenied : undefined
      }
      infoTooltip={{
        content: tooltip,
        tooltipProps: { interactive: true },
      }}
    ></SettingToggle>
  )
}
